/* Player */
.playerContainer {
  transition: all 0.1s ease-in-out;
  display: block;
  text-align: center;

}

.playerContainer > .player-inner {
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
}

.player-inner {
  display: flex;

  @include modal();
  width: 500px;
  height: 500px;
  overflow: hidden;
  button {
    @include playerButtons(auto);
    .Icon{
      width: 30px;
      height: 30px;
    }
  }

  .storyPhoto {
    max-height: 250px;
    // max-width: 100%;
    width: auto;
    position: relative;
    bottom: 0%;
  }

  h1{
  font-size: 1.5rem;
  // transform: translateY(+120%);
  }


  .audioLogic {
    display: flex;
    flex-direction: column;
    width: 90%;
    align-items: center;
    .audioButtons {
      display: flex;
      flex-direction: row;
      margin: 0 auto;
    }

    .volume-hover-container {
      transform: translate(+50%);
      position: relative;
      width: 35px;
      height: 35px;
      .volume {
        position: absolute;
        left: 0px;
        width: 30px;
        height: 30px;
      }
      &:hover > .volume-hover {
        width: 150px;
      }
      &:hover > .volume-hover > input {
        position: absolute;
        display: block;
        animation: scaleVolume 0.2s forwards;
        width: 100px;
      }
      .volume-hover {
        justify-content: left;
        width: 0px;
        height: 30px;

        text-align: center;
        input {
          transition: all 0.5s ease-in-out 0.1s;
          animation: scaleVolume 0.2s backwards;
          display: none;
          appearance: none;
          -webkit-appearance: none;
          border-radius: 50px;
          margin-top: 25%;
          width: 0px;
          height: 10px;
          left: 0px;

          &::-webkit-slider-thumb{
            -webkit-appearance: none;
            appearance: none;
            width: 15px;
            height: 15px;
            background: #ffff;
            border-radius: 50%;
            cursor: pointer;
          }
    
          &::-moz-range-thumb{
            -moz-appearance: none;
            appearance: none;
            width: 15px;
            height: 15px;
            background: #ffff;
            border-radius: 50%;
            cursor: pointer;
          }
        }
      }
    }

    @keyframes scaleVolume {
      0% {
        transform: translateX(0px);
        width: 0px;
        opacity: 0;
      }
      100% {
        transform: translateX(30px);
        width: 90px;
        opacity: 100;
      }
    }
  }

  .timeBar {
    display: flex;
    flex-direction: row;
    width: 200%;
    #timeRange {
      align-self: center;
      width: 100%;
      appearance: none;
      height: 10px;
      border-radius: 30px;

    
      &:hover{
        height: 12px;
      }

      &::-webkit-slider-thumb{
        -webkit-appearance: none;
        appearance: none;
        width: 20px;
        height: 20px;
        background: #ffff;
        border-radius: 50%;
        cursor: pointer;
      }

      &::-moz-range-thumb{
        -moz-appearance: none;
        appearance: none;
        width: 20px;
        height: 20px;
        background: #ffff;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 500px){
  .player-inner {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    .title-controls-outer{
      position: absolute;
      bottom: 10%;
      .title-controls-inner{
        display: flex;
        flex-direction: column;
        h1{
          bottom: 10%;
          height: 30px;
          
        }
        .audioPlayer{
         position: relative;
         
          width: 100%;
          height: min-content;
         
          // overflow: hidden;
          .timeBar{
            width: 100%;
          }
        }
      }
    }
    .storyPhoto{
      position: relative;
      bottom: 5%;
    }
  }
}


//duplicate code.. :(
@media screen and (max-height: 500px){
  .player-inner {
    width: 100vw;
    height: 100vh;
    min-height: 260px;

    
    .title-controls-outer{
      position: absolute;
      bottom: 10%;
    
      .title-controls-inner{
        display: flex;
        flex-direction: column;
        
        h1{
          bottom: 10%;
          height: 30px;
          
        }
        .audioPlayer{
         position: relative;
         
          width: 100%;
          height: min-content;
          .timeBar{
            width: 100%;
          }
        }
      }
     
    }
    .storyPhoto{
      position: relative;
      bottom: 10%;
      height: 50%;
    }

  }
}

// når skærm er så smal at tekst overlapper billedet
@media screen and (max-height: 280px){
  .storyPhoto{
    mask-image: linear-gradient( rgba(0, 0, 0, 1) 20%, transparent );
  }
}