.modal{
  position: absolute;
  display: flex;
  width: 100vw;
  z-index: 10;
  height: max-content;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 30px;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  opacity: 1;
}

