$audioPlayer-color:  rgba(255, 255, 255, 0.728);
$audioButtons-color: #FF934F;
$button-Hover: #e4722c;
$button-shadow: drop-shadow(2px  3px 1.5px  #565d37);
$map-resize: 0.5;
$strong-shadow:
        0 2.8px 2.2px rgba(0, 0, 0, 0.034),
        0 6.7px 5.3px rgba(0, 0, 0, 0.048),
        0 12.5px 10px rgba(0, 0, 0, 0.06),
        0 22.3px 17.9px rgba(0, 0, 0, 0.072),
        0 41.8px 33.4px rgba(0, 0, 0, 0.086),
        0 100px 80px rgba(0, 0, 0, 0.12);
$water-background: rgb(19, 157, 175);

// website colours:
$main-font: #fffbe8;
$blue-main: #0368FF;
$blue-second: #1F7ED6;
$blue-third: #0081A9;
$blue-fourth: #14a3b7;
$cream-light: #FFFBE8;
$cream-dark: #F3EBDE;
$orange-main: #FFBB0D;
$orange-slight-light: #ffc32b;
$orange-darker: #a13c00;