.shelf-container {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 3;
  overflow-y: scroll;

  .shelf-container-inner {
    padding: 10px;

    input {
      display: flex;
      width: 80%;
    }

    .DBElements {
      display: flex;
      text-align: center;
      height: 100%;
      flex-wrap: wrap;

      li {
        display: flex;
        flex-direction: column;
        // width: min-content;
        list-style: none;
        margin: 10px;
        padding: 10px;

        &:hover {
          background-color: rgb(255, 255, 255);
        }

        img {
          width: auto;
          height: 100px;
        }
      }

      .plusButton {
        width: 100px;
        height: 100px;
        border: 3px dotted rgb(143, 255, 255);
        border-radius: 20px;
        background-color: #FFF;
        color: rgb(0, 114, 114);
        font-size: 30pt;

        &:hover {
          background-color: rgb(34, 203, 255);
          border: none;
          color: white;
        }
      }
    }

    .upload-modal {
      position: fixed;
      background-color: rgba($color: rgb(255, 255, 255), $alpha: 1.0);
      padding: 30px;
      margin: 0;
      border-radius: 30px;
      border: 1px solid grey;
      box-shadow: $strong-shadow;
      backdrop-filter: blur(1px);
      overflow: hidden;

      form {
        padding: 20px 0 0 0;

        input {
          margin: 5px 0 10px 0;
        }

      }

    }

    .uploading-progress {
      padding: 0;
      margin: 0;
      top: 0;
      left: 0;
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: rgba(255, 255, 255, 0.9);
      text-align: center;

      img {
        position: relative;
        top: 40%;
        margin: 0 auto;
        max-width: 60px;
        max-height: 60px;
        animation-name: ckw;
        animation-duration: 3s;
        /* Things added */
        animation-iteration-count: infinite;
        transform-origin: 50% 50%;
        animation-timing-function: linear;
      }
    }
    #revertButton {
      background-color: rgba(243, 147, 147, 0.3);
      border: 3px dotted rgba(255, 2, 62, 0.3);
      color: rgba(88, 3, 108, 0.42);
      border-radius: 10px;
      margin: 10px;
      padding: 10px;
      font-size: 11pt;
      width: min-content;

      &:hover{
        background-color: rgba(243, 147, 147, 1);
        border: 3px dotted rgba(255, 2, 62, 1);
        color: rgba(88, 3, 108, 1);
      }
    }

      .updateContainer {
      display: flex;
      flex-direction: column;

      .saveChangesButtonContainer {
        display: flex;
        width: 100%;
        justify-content: flex-end;

        input {
          background-color: rgb(34, 203, 255);
          border: none;
          color: white;
          border-radius: 20px;
          margin: 10px;
          padding: 10px;
          font-size: 11pt;
          width: min-content;

          &:disabled {
            border: 3px dotted rgb(209, 235, 255);
            background-color: rgb(255, 255, 255);
            color: rgb(206, 215, 244);
          }
        }
      }

      .islandUpdateCheckboxes {
        display: flex;
        flex-direction: column;
        margin: 10px;
      }

      #currentIslandName, #currentElementName {
        font-size: large;
        border: 3px dotted rgb(143, 255, 255);
        border-radius: 20px;
        margin: 10px;
        padding: 10px;
        width: 90%;

        &:hover {
          border: 3px solid rgb(90, 156, 255);
        }
      }

      .islandUpdateInnerContainer {
        flex-direction: row;

        .islandUpdateButton, #audioUpload {
          margin: 10px;
          height: 100px;
          width: 150px;
          background-color: white;
          border: 3px dotted rgb(143, 255, 255);
          border-radius: 20px;
          overflow: hidden;

          &:hover {
            border: 3px solid rgb(90, 156, 255);
          }
        }
      }
    }
  }
}

@keyframes ckw {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}