.loading_screen{

    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: $water-background;
    box-shadow: 0 0 200px rgba(39, 131, 250, 0.9) inset;
    .loading_screen_inner{
        transform: translateY(10%);

        h1{
            position: absolute;
            font-size: 5vw;
            //text-decoration: none;
            color: white;
            margin-left: 38%;
            margin-top: 5%;
            margin-bottom: 0%;
        }
        .dot{
            position: absolute;
            font-size: 5vw;
            //text-decoration: none;
            color: white;
            //transform: translateY(-30%);
            opacity: 0;
            margin-left: 60%;
            margin-top: 5%;
            animation: dot_to_right 4s infinite;
        }
        @keyframes dot_to_right {
            0%{opacity: 0;}
            25%{margin-left: 61%;}
            28%{opacity: 1;}
            38%{opacity: 0;}
            50%{margin-left: 62%;}
            51%{opacity: 1;}
            63%{opacity: 0;}
            75%{margin-left: 63%;}  
            76%{opacity: 1;} 
            88%{opacity: 0;}
            89%{margin-left: 64%;}
        }  
    

        .baad{
            z-index: 1;
            position: absolute;
            justify-content: center;
            align-items: center;
            margin-left: 38%;
            margin-top: 18%;
            width: 25%;
        
            animation: sail 2s infinite;
        }

        @keyframes sail {
            0%{transform: rotate(0deg)};
            25%{transform: rotate(3deg) translateY(5px)};
            75%{transform: rotate(-5deg) translateY(-5px)};
            100%{transform: rotate(0deg)};
        }
        .sol{
            z-index: 0;
            position: absolute;
            justify-content: center;
            align-items: center;
            margin-top: 20%;
            transform: translate(150%, -24%);
            width: 25%;
        }

        .wave1{
            z-index: 2;
            position: absolute;
            justify-content: center;
            align-items: center;
            width: 25%;
            margin-top: 38%;
            margin-left: 38%;

            animation: wave1Effect 2s infinite;
        }
        @keyframes wave1Effect {
            0%{transform: translateY(0px)};
            25%{transform: translateY(3px )};
            75%{transform: translateY(-3px)};
            100%{transform: translateY(0px)};
        }


        .wave2{
            position: absolute;
            margin-top: 35%;
            margin-left: 20%;
            width: 15%;
            animation: wave2Effect  2s infinite;
        }
        @keyframes wave2Effect {
            0%{transform: translateY(0px)};
            25%{transform: translateY(-3px )};
            75%{transform: translateY(3px)};
            100%{transform: translateY(0px)};
        }

        .wave3{
            position: absolute;
            margin-top: 33%;
            margin-left: 66%;
            width: 15%;
            animation: wave3Effect 2s infinite;
        }
        @keyframes wave3Effect {
            0%{transform: translateY(0px)};
            25%{transform: translateY(-3px )};
            75%{transform: translateY(3px)};
            100%{transform: translateY(0px)};
        }
    }
}