header{
    position: absolute;
    display: inline-flex;
    z-index: 2;
    width: max-content;
    height: 100px;
    // background-color: rgb(136, 102, 50);
    // display: inline-block;
    
    .title{
        position: absolute;
        left: 10px; 
    }
   
    .logo{
        width: auto;
        height: 50%;
        padding: 20px;
    }
}

@media screen and (max-width: 500px) {

    header{
        .logo{
            width: auto;
            height: 30px;
        }
    }
}
