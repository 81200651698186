.bruger-input-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  justify-content: center;

  .bruger-input-inner {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 450px;
    height: 300px;

    .bruger-input-inner-box {
      position: relative;
      display: flex;
      //position: relative;
      justify-content: center;
      background-color: white;
      width: 100%;
      height: 100%;
      padding: 10px;
      border: 1px solid $blue-main;
      border-radius: 10px;
      box-sizing: border-box;
      align-items: center;

      #loggerDigInd {
        position: absolute;
        padding: 0;
        margin: 0;
        top: 20%;
      }
      .validator_ikon {
        position: absolute;
        display: inline;
        height: 25px;
        bottom: 5px;
        top: 40%;

        & img {
          align-self: center;
          width: 25px;
          transform-origin: center;

        }

        &.loading {
          transform: translateY(-1px);
          transform-origin: center;
          animation: rotate_infinite;
          animation-duration: 1s;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
        }


      }
      #fejlbesked {
        position: absolute;
        top: -10px;
        font-size: 15px;
        padding-top: 5%;
        color: red;
        text-align: center;

      }

      @keyframes fadeout {
        0% {
          opacity: 100;
        }
        90% {
          opacity: 100;
        }
        100% {
          opacity: 0;
        }
      }
      @keyframes rotate_infinite {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
      //#googleSignIn{
      //    transition: all .1s ease-in-out;
      //    display: flex;
      //    align-items: center;
      //    width: 100%;
      //    height: 50px;
      //    font-family: 'Roboto', sans-serif;
      //    font-weight: 300;
      //    background-color: white;
      //    border: 1px solid $blue-main;
      //    border-radius: 10px;
      //    color: black;
      //    padding: 5px;
      //    min-width: 240px;
      //    &:hover{
      //        background-color: rgb(212, 241, 255);
      //
      //    }
      //    img{
      //        width: 20px;
      //        padding: 30px;
      //        // margin: auto;
      //    }
      //    p{
      //        margin: auto;
      //        transform: translateX(-20px);
      //        max-height: 20px;
      //    }
      //
      //}

      #eller {
        padding-left: 20px;
        font-size: small;
        color: black;

      }

      form {
        label {
          color: $blue-main;
          margin: 10%;

          &.required {
            &::before {
              content: "*";
              color: rgb(255, 102, 0);
            }
          }
        }

        .formInput {
          position: relative;
          transition: all 0.2s ease-in-out;
          transform: scale(1);
          width: 80%;
          height: 30px;
          border: 1px solid $blue-main;
          border-radius: 10px;
          padding-left: 10px;
          margin: 2% 10%;
          box-sizing: border-box;
          &:hover {
            background-color: rgb(212, 241, 255);
          }

        }

        .submit {
          margin: 5% 10%;
          width: 80%;
        }

       a#glemtkodeNav{
          display: flex;
          position: relative;
          margin-top: 20px;
          color: $blue-main;
        }
      }
    }
  }

  .tilmeld {
    transform: translateX(355px);
  }
  .hav {
    @include havBaggrund();
  }
}

@media screen and (max-width: 550px) {
  .bruger-input-container {
    #tilbage {
      background-color: white;
      color: $blue-main;
    }
    .tilmeld {
      position: absolute;
      transform: none;
      right: 0px;
      bottom: 20px;
      width: max-content;

    }

    .bruger-input-inner {
      .bruger-input-inner-box{
        border: none;
        background-color: transparent;
        #fejlbesked {
          color: #ffffff;
          top: -50px;
          padding: 0;
        }
        form {
          label {
            color: white;
          }
          .formInput {
            background-color: transparent;
            border: 2px solid white;
            color: white;

            &:hover{
              color: $blue-main;
            }
          }
            //border: white;
          a {
            color: white;
          }
          a#glemtkodeNav{
            color: white;
            font-weight: bold;
          }
        }
      }
    }
  }
}
