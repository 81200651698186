
ul li{
    list-style: none;
}
.frame{
    position: relative;
    overflow:scroll;
    z-index: 1;
    margin: 0px;
    padding: 0px;
    height: 100%;
    width: 100%;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    // flex-flow: column;
}

.frame::-webkit-scrollbar {
    display: none;
  }

.glow {
    width: 0px;
    height: 0px;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    z-index: -1;

    transform-origin: 50% 50%;
    mix-blend-mode: hard-light;
    box-shadow: 0 0 100px 60px rgb(255, 255, 255),
    0 0 130px 120px rgb(255, 230, 0),
    0 0 160px 150px rgb(255, 115, 0),
    0 0 160px 165px rgb(181, 0, 253);
    animation: pulse 3s infinite;

    @keyframes pulse {
        0% {
            transform: scale(0.7);
            opacity: 0.5;
        }
        45% {
            transform: scale(0.3);
            opacity: 1;
        }
        55% {
            transform: scale(0.3);
            opacity: 1;
        }
        100% {
            transform: scale(0.7);
            opacity: 0.5;
        }
    }
}

  
