#text, .character {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.character {
  position: absolute;
  top: 0;
  left: 0;

  z-index: 1;

  &:hover {
    cursor: pointer;

    > &:after {
      transform: scale(10);
    }

    img {
      user-select: none;
      pointer-events: none;
    }
  }

  &.locked {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%) ;
    -o-filter: grayscale(100%) ;
    cursor: default;
  }
  &.disabled {
    cursor: default;
  }
}

