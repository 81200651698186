.dashboard {
  @include modal();
  position: absolute;
  width: 500px;
  height: 500px;
  overflow-y: scroll;
  margin: 0;
  top: 50%;
  left: 50%;

  #laas {
    position: absolute;
    display: flex;
    margin: auto;
    padding: 0;
    flex-direction: column;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;

    #blurry-bg {
      position: absolute;
      margin: auto;
      width: 99%;
      height: 99%;
      background-color: rgba(255, 255, 255, 0.9);
      backdrop-filter: blur(30px);
      filter: blur(50px);
      left: -50px;
      border-radius: 10%;
    }

    #laas-inner {
      margin: auto;
      z-index: 9;
      width: 100%;
      // filter: blur(0px);
      input {
        position: relative;
        transition: all .2s ease-in-out;
        transform: scale(1);
        margin-bottom: 10px;
        width: 80%;
        height: 30px;
        border: 1px solid $blue-main;
        border-radius: 10px;
        padding-left: 10px;

        &:hover {
          background-color: rgb(212, 241, 255);

        }
      }

      .submit {
        position: relative;
        margin: 20px 0 0 0;
        width: 30%; // dette er en hack. Knappen bliver kortere end de andre inputfelter pga. den nødvendige invendige padding, jeg giver dem.
        min-width: max-content;

        &:enabled {
          background-color: $main-font;
          border: 1px solid $orange-main;
          transform: scale(1.05);

          &:hover {
            background-color: $orange-darker;
          }
        }
      }
    }
  }

  a {
    color: $blue-main;
  }

  .bruger-input-box {
    width: 100%;

    .bruger-input-box-inner {
      display: flex;
      width: 100%;
      flex-direction: row;
      form {
        display: flex;
        width: 90%;
        flex-direction: column;

        label {
          padding-bottom: .2%;
          color: $blue-main;
          width: min-content;
        }

        #divider {
          display: flex;
          margin: 10px;
          width: 70%;
          height: 30px;
          border-top: 1px solid $blue-main;
          padding-bottom: 5%;

          .nulstil {
            display: inline-flex;
          }

          p {
            width:  100%;
            padding-left: 10%;
          }
        }

        input {
          position: relative;
          transition: all .2s ease-in-out;
          transform: scale(1);
          margin-bottom: 10px;
          width: 80%;
          height: 30px;
          border: 1px solid $blue-main;
          border-radius: 10px;
          padding-left: 10px;

          &:hover {
            background-color: rgb(212, 241, 255);
          }
        }

        .submit {
          position: relative;
          margin: 20px 0 0 0;
          width: 30%; // dette er en hack. Knappen bliver kortere end de andre inputfelter pga. den nødvendige invendige padding, jeg giver dem.
          min-width: max-content;

          &:enabled {
            background-color: $main-font;
            border: 1px solid $orange-main;
            transform: scale(1.05);

            &:hover {
              background-color: $orange-darker;
            }
          }

          &:disabled {
            background-color: white;
            border: 1px solid grey;
          }
        }
      }

      .profilbillede-container {
        position: relative;
        display: flex;
        max-width: 150px;
        max-height: 150px;
        //margin: auto 10%;
        @include profilbillede(30%, 30%);
      }
    }
  }
}

@media screen and(max-width: 600px){
  .dashboard {
    width: 100vw;
    height: 100vh;
    align-items: center;

    .bruger-input-box, h1 {
      margin: 20px;
    }
    h1 {
     text-align: end;
    }
    .bruger-input-box{
      .bruger-input-box-inner {
        width: 100%;
        flex-direction: column;
        form {
          input {
            width: 90%;
          }
        }
      }
    }

    #laas {
      width: 100%;

      #laas-inner {
        box-sizing: border-box;
        width: 60%;
      }
    }
  }
}