@import "src/components/style/Utility/StyleMixins";
@import "src/components/style/Utility/StyleVariables";
@import "src/components/style/Utility/Divider";
@import "src/components/style/Utility/CloseOutside";

@import "./components/style/Login";
@import "src/components/style/PlayerModal";
@import "./components/style/Map";
@import "./components/style/Havet";
@import "./components/style/Island";
@import "./components/style/Header";
@import "./components/style/SideMenu";
@import "./components/style/Footer";
@import "./components/style/EditorShelf";
@import "./components/style/Characters";
@import "./components/style/Modal";
@import "src/components/style/Utility/LoadingScreen";
@import "src/components/style/DashboardModal";
@import "./components/style/Knapper";
@import "./components/style/PayWall";


@import url('https://fonts.googleapis.com/css2?family=Shippori+Mincho&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans+Narrow:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap');
.safari_transform {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
}

html, body,.root,#app{
    position: relative;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans Narrow', sans-serif;
    width: 100%;
    height: 100vh;
    background-color: $blue-fourth;
    overflow: hidden;
}
a{
    text-decoration: none;
    &:visited {
        color: inherit;
    }
}
.noselect {
    /* Prevent text selection */
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.body-inner{
    display: flex;
    width: 100%;
    height: 100vh;
    flex-direction: row;
}

.map-app{
    width: 130%;
    float: left;
    overflow: hidden;
    
}
.editor-enabled,.editor-app{
    margin: 10px;
    height: 100%;
    overflow: hidden;
    border: 1px dashed grey;   
}
.editor-app{
    width: 80%;
    float: right;

}



