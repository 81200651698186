.knap {
    border-radius: 10px;
    position: relative;
    margin-top: 20px;
    width: 100%; // dette er en hack. Knappen bliver kortere end de andre inputfelter pga. den nødvendige invendige padding, jeg giver dem.
    height: 30px;
    border: none;
    cursor: pointer;
    &.gul{
      &:enabled {
        background-color: $main-font;
        border: 1px solid $orange-main;
        transform: scale(1.05);
        color: $orange-darker;
        &:hover {
          background-color: $orange-slight-light;
        }
      }
      &:disabled {
        background-color: white;
        border: 1px solid grey;
      }
    }

    &.outline {
        color: white;

        a {
          font-size: smaller;
          margin: 5px;
          padding: 10px;
          border: 1px solid white;
          border-radius: 10px;
          color: white;
        }
    }
}


#close{
  position: absolute;
  top: 30px;
  left: 30px;
  cursor: pointer;
  z-index: 4;
}

.side-menu-knap {
    display: block;
    margin: 0 auto;

    transition: all .1s ease-in-out;
    background-color: rgba($color: #ffffff, $alpha: 0);

    cursor: pointer;

    width: 80%;
    height: 40px;

    align-self: center;
    border-radius: 30px;
    &:hover {
      background-color: rgba($color: #ffffff, $alpha: 1);
    }

    input {
      border: none;
      background: none;
      font-weight: bold;
      text-align: center;
      height: 100%;
      cursor: pointer;
    }
}

#tilbage {
  transition: all .2s ease;
  z-index: 2;
  position: fixed;
  top: 100px;
  background-color: steelblue;
  padding: 10px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  color: white;
  left: 0px;

  #tilbagepil{
    position: absolute;
    opacity: 0;
    left: 30px;
  }

  &:hover{
    padding: 13px 13px 13px 40px;
    background-color: rgb(112, 165, 198);
  }

  &:hover > #tilbagepil{
    opacity: 100;
    width: 40px;
    left: 10px;
    animation: pil 0.5, infinite;
  }
}

#skjul {
  position: absolute;
  width: 100px;
  left: 50%;
  top: 30%;
  transform: translateX(-50%) scale(1.5);
  z-index: 3;
  cursor: pointer;

  svg {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  #close{
    left: 80px;
    top: 80px;
    transform: scale(1.5);
  }
}
