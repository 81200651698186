@mixin playerButtons($widthSize) {
    width: $widthSize;
    background-color: transparent;
    border-radius: 50%;
    border: transparent;
    display: flex;
    font-style: normal;
    font-weight: bold;
    font-size: 0.75rem;
    line-height: 0.9rem;
    align-items: center;
    justify-content: center;
    transition: all .1s ease-in;
        &:hover{
            &:hover {
                .Icon {
                  columns: $button-Hover;
                }
              }
            cursor: pointer;
        }
}

@mixin profilbillede($width, $height){
    display: flex;
    width: $width;
    height: $height;
    border-radius: 50%;
    border: 5px solid white;
    align-items: center;
    img{
      width: 100%;
      height: 100%;
    }
  cursor: pointer;
}
@mixin havBaggrund(){
  pointer-events: none;
  height: 1900px;
  width: 2600px;
  top: 0px;
  left: 0px;
  position: absolute;
  z-index: -10;
  overflow: hidden;
}

@mixin box-shadow-laekker() {
  -webkit-box-shadow: 15px 10px 58px 0px rgba(0,0,0,0.3);
        -moz-box-shadow: 15px 10px 58px 0px rgba(0,0,0,0.3);
        box-shadow: 15px 10px 58px 0px rgba(0,0,0,0.3);
}

@mixin modal(){
  flex-direction: column;
  position: absolute;
  justify-content: center;
  align-items: center;
  background-color: $audioPlayer-color;
  border-radius: 20px;
  padding: 50px;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}