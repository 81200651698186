// lidt ærgerligt at have en body her, men ellers er kanten hvid i chrome
body{
    box-shadow: 0 0 200px rgba(39, 131, 250, 0.9) inset;
}

#map-container{
    width: 100%;
    height: 100vh;    
    overflow: hidden;
    margin: 0;
    padding: 0;
    background-color: #11a9be;
}
.map-inner{

    transform-origin:top left;
    height: 100vh;
}
.map{
    position: absolute;
    top: 0;
    left: 0;
    user-select: none;
    pointer-events: none;

}

#canvas{
    display: none;
    top: 0px;
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 15;
    user-select: none;
    pointer-events: none;
    .canvas-island{
        z-index: -1;
    }
}
.hav{
    @include havBaggrund();
}

.waves-and-fish{
    user-select: none;
    pointer-events: none;
    top: 0px;
    position: absolute;
    height: 1200px;
    width: 1200px;
    z-index: -1;

   .wave{
        filter: brightness(1.2) saturate(0.8);
   }

   #f1{
        width: 240px;
        position: absolute;
        bottom: -30px;
        left: 10px;
    }

    #f2{
        width: 200px;
        position: absolute;
        bottom: -200px;
        right: -850px;
        opacity: .5;
    }
    
    #w1{
        width: 70px;
        position: absolute;
        top: 100px;
        right: 400px;
    }

    #w2{
        width: 50px;
        position: absolute;
        bottom: 30px;
        left: 900px;
    }

    #w3{
        width: 90px;
        position: absolute;
        top: 350px;
        right: -470px;
    }

    #w4{
        width: 90px;
        position: absolute;
        top: 250px;
        left: 100px;
    }

    #w5{
        width: 50px;
        position: absolute;
        bottom: 300px;
        left: 500px;
    }

    #w6{
        width: 70px;
        position: absolute;
        bottom: 300px;
        right: -570px;
    }

    #w7{
        width: 70px;
        position: absolute;
        top: 280px;
        left: 800px;
    }
}
.skyer{
    user-select: none;
    pointer-events: none;
    top: 0px;
    position: absolute;
    height: 1900px;
    width: 2600px;
    filter: drop-shadow(0px 170px 40px #023e7063);
    -webkit-filter: drop-shadow(0px 170px 40px #023e7063);
    overflow: hidden;
    #btm-right{
        z-index: 10;
        position: absolute;
        bottom: -80px;
        right: -200px;
    }

    #btm-left{
        z-index: 10;
        position: absolute;
        bottom: -150px;
        left: -200px;
    }

    #top-right{
        z-index: 10;
        position: absolute;
        top: -80px;
        right: -150px;
    }
    #top-left{
        z-index: 10;
        position: absolute;
        top: -200px;
        left: -400px;
    }
}
.island{
    z-index: -1;
    display: block;
    position: absolute;
}
.islandImage-container{
    z-index: -1;
    width: 100%;

    .islandImage{
        z-index: 2;

        &.cssgradient {
            //enten gradient
            filter: drop-shadow(0 0 20px rgb(196, 192, 95));
            -webkit-filter: drop-shadow(0 0 20px rgb(196, 192, 95));
         }

        &.dropshadow {
            // eller drop-shadow
                filter: drop-shadow(0px 50px 30px rgba(3, 99, 81, 0.47));
                -webkit-filter: drop-shadow(0px 50px 30px rgba(3, 99, 81, 0.47));
        }

    }
    &.cssgradient {
        &:before{
            position: absolute;
            z-index: -3;
            content: "";
            left: -25%;
            top: 5%;
            width: 150%;
            height: 150%;
            background-image:radial-gradient(rgba(253, 199, 165, 0.75) 0%, rgba(40, 235, 50, 0.0) 50%);
            -webkit-background-image:radial-gradient(rgb(255, 211, 153), rgba(40, 235, 50, 0.363));
            border-radius: 100%;
        }
    }

}

.islandTitle{
    z-index: 15;
    color: white;
    text-align: center;
}
