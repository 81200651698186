.menu-logget-ud {
  position: absolute;
  right: 200px;
  top: 20px;
  z-index: 10;
  width: 250px;
  height: 100px;

}

.loginknap-container, .menu-container {
  position: absolute;
  display: flex;
  z-index: 10;
}
.loginknap-container {
  width: 150px;
  right: 30px;
  height: min-content;
}
.menu-container {
  flex-direction: row;
  padding: 0;
  right: 0;
  width: 40vw;
  backdrop-filter: blur(4px);
  text-align: center;
  background-color: rgba($color: #ffffff, $alpha: 0.9);
  transition: width .2s cubic-bezier(0, 1.11, .74, 1.01);
  height: 100%;
  &.closed {
    width: 0vw;
    height: 0%;

    .menu-container-inner {
      .profilbillede-container {
        right: 60px;
      }
    }
  }

  .menu-container-inner {
    position: relative;
    width: 100%;

    .loginknap-container {
      position: absolute;
      right: 30px;
      top: 10px;
      z-index: 1;

      .knap {
        box-sizing: border-box;
        text-align: center;
        padding: 0 10px;
      }
    }

    .profilbillede-container {
      transition: right .2s cubic-bezier(0, 1.11, .74, 1.01);
      position: absolute;
      right: 50%;
      transform: translateX(50%);
      top: 20px;
      z-index: 1;
      @include profilbillede(80px, 80px);
      @include box-shadow-laekker();
    }

    .menu-content {
      margin: auto;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      align-items: center;
      .menu-content-inner {
        margin: auto;
        width: 100%;
      }
      #log-ud {
        position: absolute;
        bottom: 5%;
        font-weight: bold;
      }
    }

  }
}

@media only screen and (max-width: 650px) {
  .menu-container {
    width: 100vw;
  }
}