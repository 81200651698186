

.islandHeader{
    position: absolute;
    z-index: 2; 
}

.island-map-inner{
    position: absolute;
    top: 0;
    left: 0;
    transform: scale($map-resize);
}

.locked {
    filter: grayscale(100%) opacity(.8);
    -webkit-filter: grayscale(100%) opacity(.8);
    -moz-filter: grayscale(100%) opacity(.8);
    -o-filter: grayscale(100%) opacity(.8);
    cursor: default;
}

@keyframes pil {
    from{
        left: 30px;
    }
    to{
        left: 10px;
    }
}
