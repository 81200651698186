.paywall {
  position: absolute;
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(250, 250, 250, 1) 66%, rgba(255, 255, 255, 0) 100%);
  z-index: 2;
  bottom: 0px;
  width: 100vw;
  height: 20%;
  min-height: 150px;
  transition: all .3s ease-in-out;
  opacity: 1;

  &.aaben {
    height: 100%;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);

    #lukkeomraade {
      width: 100%;
      height: 30%;
    }

  }

  &.lukket {
    bottom: -100%;
    opacity: 0;
  }

  .paywall_inner {
    position: absolute;
    bottom: 0px;
    height: 70%;
    width: 100%;
    text-align: center;

    #blurry-bg {
      position: relative;
      margin: auto;
      width: 500px;
      height: 100%;
      background-color: rgba(255, 255, 255, 100);
      filter: blur(50px);
      border-radius: 10%;
      z-index: 0;
      transform: translateY(-30px);
    }

    .besked {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 2;
      width: 500px;
      height: 70%;
      left: 50%;
      bottom: 50px;
      transform: translateX(-50%);
      white-space: pre-line;

      h1 {
        margin: 0px auto;
      }

      h2 {
        font-size: smaller;
      }

      .storyPhoto {
        height: 100%;
      }

      .tilmeld {
        display: flex;
        flex-direction: column;
        width: 200px;
        margin: 0 auto;
        padding: 0;

        .knap {
          margin-top: 10px;
        }
      }

      .logind-container {
        display: flex;
        flex-direction: row;
        width: 210px;
        padding-top: 5px;

        span {
          display: inline-block;
        }

        .logind {
          cursor: pointer;
          font-weight: bold;
          width: 55px;
          border: none;
        }
      }
    }
  }

  .images_container {
    position: relative;
    height: 100%;
    z-index: 0;

    &.aaben {
      display: none;
    }

    .paywall_image {
      max-width: 200px;
    }


    #lochness_b {
      position: absolute;
      left: 5%;
      top: 25%;
    }

    #lochness_f {
      position: absolute;
      right: 10%;
      bottom: 10%;
    }
  }
}

@media screen and (max-width: 600px) {
  .paywall {
    .images_container, #blurry-bg {
      display: none;
    }
  }
}

@media screen and (max-width: 375px) {
  .paywall {
    height: 30%;
    min-width: 230px;

    .paywall_inner {
      .besked {
        width: 100%;

        h1 {
          font-size: 15pt;
        }
      }
    }
  }

}